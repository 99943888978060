import * as React from 'react';
import { cn } from '@/lib/utils';
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn('flex w-full rounded-md border-2 border-[#002eaf33] hover:border-[#002eaf66] bg-white p-3 text-secondary font-bold text-base file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary/[0.35] focus-visible:outline-none focus-visible:border-secondary disabled:cursor-not-allowed disabled:opacity-50', className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';
export { Input };