import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const alertVariants = cva('relative w-full rounded-lg border border-zinc-200 p-4 [&>svg~*]:pl-10 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4', {
  variants: {
    variant: {
      default: '[&>svg]:text-secondary-readable bg-transparent text-secondary-readable',
      destructive: 'border-red-500/50 text-red-500 [&>svg]:text-red-500'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
const Alert = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>>(({
  className,
  variant = 'default',
  ...props
}, ref) => <div ref={ref} role="alert" className={cn(alertVariants({
  variant
}), className)} {...props} />);
Alert.displayName = 'Alert';
const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({
  className,
  ...props
}, ref) => <h5 ref={ref} className={cn('font-medium font-heading text-sm leading-none tracking-wide', className)} {...props} />);
AlertTitle.displayName = 'AlertTitle';
const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  ...props
}, ref) => <div ref={ref} className={cn('space-y-6 text-sm [&_p]:text-sm [&_p]:leading-relaxed', className)} {...props} />);
AlertDescription.displayName = 'AlertDescription';
export { Alert, AlertTitle, AlertDescription };