'use client';

import { LoaderIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { cn } from '@/lib/utils';
interface LoadingAlertProps {
  title?: string;
  description?: string;
  background?: boolean;
  icon?: React.ReactNode;
}
const classes = {
  background: 'bg-background'
};
export function LoadingAlert({
  title = 'Working',
  description = 'Please wait.',
  background = false,
  icon
}: LoadingAlertProps) {
  const [text, setText] = useState(description);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setText('Loading is taking longer than expected. We appreciate your understanding.');
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return <Alert className={cn('space-y-1', {
    [classes.background]: background
  })}>
      {icon ?? <LoaderIcon color="rgb(0, 30, 113)" className="h-4 w-4 animate-spin" />}
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{text}</AlertDescription>
    </Alert>;
}