import { v4 } from 'uuid';
import classes from '@/components/Search/SearchResults/classes';
import { LoadingAlert } from '@/components/Shared/LoadingAlert';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
function SearchResultsListItemSkeleton() {
  return <Card>
      <CardHeader>
        <Skeleton className="h-[128px] w-[128px]" />

        <CardTitle>
          <div className="space-y-2">
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex">
          <div className="flex flex-col gap-5 w-6/12"></div>
          <div className="flex flex-col gap-5 w-6/12"></div>
        </div>
      </CardContent>
    </Card>;
}
export function SearchResultsSkeleton() {
  return <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className="flex flex-col items-center justify-between">
          <LoadingAlert title="Searching" />

          <div className="flex flex-col gap-5 mt-5 w-full">
            {new Array(15).fill(null).map(() => <SearchResultsListItemSkeleton key={v4()} />)}
          </div>
        </div>
      </div>
    </div>;
}