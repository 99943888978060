'use client';

import { useSearchParams } from 'next/navigation';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import classes from './classes';
export function SearchSidebarSkeleton() {
  const searchParams = useSearchParams();
  return <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.input}>
          <Input id="search-input" disabled placeholder="Filter by keyword" value={searchParams.get('query') ?? ''} />
        </div>

        <div className={classes.group}>
          <h4>Sources</h4>
          <div className={classes.facet}>
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>

        <div className={classes.group}>
          <h4>Countries</h4>
          <div className={classes.facet}>
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>

        <div className={classes.group}>
          <h4>Keywords</h4>
          <div className={classes.facet}>
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>
      </div>
    </div>;
}